import React, { Component } from "react";
import Form from "antd/es/form";
import Icon from "antd/es/icon";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Tabs from "antd/es/tabs";
import Select from "antd/es/select";
import DatePicker from "antd/es/date-picker";
import { Link, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import moment from "moment";
// import es from "react-intl/locale-data/es";
// import firebase from "./conect_fb";
import GoogleConnet, { ErrorModuleGoogle } from "./services/OAuth";
import "./App.css";
import "antd/dist/antd.min.css";
// import appadmin from "./App_admin";
import Mainapp from "./Mainapp";
// import MainAdmin from "./MainAdmin";	
import Mainpolla from "./Mainpolla";
import * as func from "./func";
import { hashSet } from './services/general/index';
var _ = require("lodash");
const encode = require("nodejs-base64-encode");
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
const Option = Select.Option;
// addLocaleData(es);

class FromRegistro extends Component {
	constructor(props) {
		super(props);

		this.state = {
			iu: "",
			pw: "",
			email: "",
			name: "",
			ntfn: "",
			cb: "",
			id: "",
			fn: "946699200",
			ntlf: "",
			tc: 1,
			banco: 0,
			avatar: "",
			tabs: "1",
			statePw: false,
			stateUser: false,
			stateBtn: false,
			isAuthenticated: false,
			bancos: [],
			validateIU: [],
		};
		this.refInputRegister = React.createRef();
		this.grabarIU = func.grabarIU.bind(this);
		this.searchIU = func.searchIU.bind(this);
		this.queryviewBancosFn = func.queryviewBancosFn.bind(this);
	}
	handleWebsiteChange = () => {
		this.props.form.validateFields((err, valores) => {
			if (!err) {
				this.callregistroNew(valores);
			}
		});
	};
	callregistroNew = (valores) => {
		var recordnew = {
			iu: -1,
			Usuario: valores.iu,
			Nombre: valores.name,
			email: valores.email,
			tipo: 2,
			identif: valores.id,
			Moneda: "BSS",
			idioma: "1",
			activo: 0,
			tope: 10,
			pw: encode.encode(JSON.stringify(valores.pw), "base64"),
			registro: 0,
			pago: 1,
			ticket: 0,
			fn: _.toString(moment(valores.fn).unix()),
			tc: valores.tc,
			banco: 1, //valores.banco,
			cb: valores.cb,
			ntlf: valores.prefix + "-" + valores.ntlf,
			avatar: this.state.avatar,
			nmsg: 0,
			receptor: 0,
			Deporte: true,
			Americana: true,
			Nacionales: true,
			Loteria: true,
		};
		this.grabarIU(recordnew, 2);
	};
	initFromApp = () => {
		this.setState({
			iu: "",
			pw: "",
			email: "",
			name: "",
			ntfn: "",
			cb: "",
			id: "",
			fn: "946699200",
			ntlf: "",
			tc: 1,
			banco: 0,
			avatar: "",
			statePw: false,
		});
		this.props.tthis.inicTabs("1");
		this.props.form.resetFields();
	};
	setStatusIU = (validateIU) => {
		this.setState({ validateIU });
	};
	setDatalist = (bancos) => {
		this.setState({ bancos });
	};
	validateLogin = (rule, value, callback) => {
		var xvalue = _.toLower(value.replace(/ /g, ""));
		if (_.indexOf(this.state.validateIU, xvalue) !== -1)
			callback("Lamentablemente este usuario ya existe!");
		else callback();
	};
	RegistoGoogle = (response) => {
		const { error } = response;
		if (!error) {
			const {
				displayName,
				email,
				photoURL,
				uid,
			} = response;
			// if (operationType === )
			const viewLogin = email.split("@");
			const iu = viewLogin[0];
			const pw = uid;
			const name = displayName;
			const avatar = photoURL;

			this.setState({
				email,
				iu,
				pw,
				name,
				avatar: avatar,
				statePw: true,
			});
		} else {
			ErrorModuleGoogle(error);
		}
	};
	componentDidMount = () => {
		this.searchIU();
		this.queryviewBancosFn();
	};
	render() {
		const { getFieldDecorator } = this.props.form;
		var fecha = moment.unix(this.state.fn).format("YYYY-MM-DD");
		const prefixPhone = getFieldDecorator("prefix")(
			<Select style={{ width: 70 }}>
				<Option value="414">414</Option>
				<Option value="424">424</Option>
				<Option value="416">416</Option>
				<Option value="426">426</Option>
				<Option value="412">412</Option>
			</Select>
		);
		return (
			<div style={{ padding: "24px" }}>
				<GoogleConnet
					text={"Registrate con Google"}
					onSummit={this.RegistoGoogle}
				/>
				<br />
				<div style={{ textAlign: "center" }}>
					<label>o</label>
				</div>
				<br />

				<Form className="form_regs">
					<FormItem>
						{getFieldDecorator("iu", {
							initialValue: this.state.iu,
							rules: [
								{
									type: "string",
									message:
										"Debe tener algun nombre de usuario, si es valido mejor!",
								},
								{
									required: true,
									message: "Por favor requiero algun Nombre!",
								},
								{
									validator: this.validateLogin,
								},
							],
						})(
							<Input
								ref={this.refInputRegister}
								prefix={
									<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Ingresa tu Usuario"
								disabled={this.state.statePw}
								autoFocus={true}
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("pw", {
							initialValue: this.state.pw,
							rules: [
								{
									required: true,
									message: "Por favor coloque su clave!",
								},
								{
									validator: this.validateToNextPassword,
								},
							],
						})(
							<Input
								prefix={
									<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								type="password"
								placeholder="Tu Clave"
								disabled={this.state.statePw}
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("email", {
							initialValue: this.state.email,
							rules: [
								{
									type: "email",
									message: "Este correo no es valido!",
								},
								{
									required: true,
									message: "Por Favor el correo es requerido!",
								},
							],
						})(
							<Input
								prefix={
									<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Correo Electronico"
								disabled={this.state.statePw}
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("ntlf", {
							initialValue: this.state.ntlf,
							rules: [
								{
									type: "string",
									message: "Introduce un Numero Telefonico!",
								},
							],
						})(
							<Input
								addonBefore={prefixPhone}
								prefix={
									<Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Numero Telefonico"
							/>
						)}
					</FormItem>
					<FormItem label="Fecha de Nacimiento">
						{getFieldDecorator("fn", {
							initialValue: moment(fecha, "YYYY-MM-DD"),
							rules: [
								{
									type: "object",
									required: true,
									message: "Por Favor coloque su fecha de Nacimiento!",
								},
							],
						})(
							<DatePicker
								placeholder="Fecha de Nacimiento"
								format="DD/MM/YYYY"
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("cb", {
							initialValue: this.state.cb,
							rules: [
								{
									type: "string",
									message: "Introduce una cuenta bancaria Valida!",
								},
							],
						})(
							<Input
								prefix={
									<Icon type="bank" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Cuenta Bancaria"
							/>
						)}
					</FormItem>
					{/* <FormItem label="Seleccione el Banco" >
            {getFieldDecorator("banco", {
              initialValue: this.state.banco,
            })(
              <Select placeholder="Seleccione el Banco">
                <Option value={0}></Option>
                {this.state.bancos.map((value) => (
                  <Option value={value.key}>{value.Text}</Option>
                ))}
              </Select>
            )}
          </FormItem> */}
					<FormItem label="Tipo de Cuenta">
						{getFieldDecorator("tc", {
							initialValue: this.state.tc,
						})(
							<Select>
								<Option value={1}>Corriente</Option>
								<Option value={2}>Ahorros</Option>
							</Select>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("name", {
							initialValue: this.state.name,
							rules: [
								{
									type: "string",
									message: "Debe tener algun nombre, si es valido mejor!",
								},
								{
									required: true,
									message: "Por favor requiero algun Nombre!",
								},
							],
						})(
							<Input
								prefix={
									<Icon type="user-add" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Nombre Completo"
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator("id", {
							initialValue: this.state.id,
							rules: [
								{
									type: "string",
									message: "Introduce una identifacion valida!",
								},
								{
									required: true,
									message: "Por favor requiero una identificacion valida",
								},
							],
						})(
							<Input
								prefix={
									<Icon type="info" style={{ color: "rgba(0,0,0,.25)" }} />
								}
								placeholder="Identifiacion (DIN/CI/PASSAPORTE)"
							/>
						)}
					</FormItem>
					<FormItem>
						<label>
							<lablel className="markResalApp">*</lablel>Los Datos Son
							Necesarios para realizar sus pagos(abonos) via PagoMovil
						</label>
					</FormItem>
					<FormItem>
						<br />
						<div style={styles.buttonIntro}>
							<Button
								className="buttomRegistrame"
								block
								ghost
								onClick={(e) => this.handleWebsiteChange(e)}
								disabled={this.state.stateBtn}
							>
								Registrame!
							</Button>
						</div>
						<br />
					</FormItem>
				</Form>
				<br />
				<br />
			</div>
		);
	}
}
const RegistroForm = Form.create()(FromRegistro);
class App extends Component {
	constructor(props) {
		super(props);
		this.myPasswd = React.createRef();
		this.myButton = React.createRef();
		this.state = {
			iu: "",
			pw: "",
			email: "",
			name: "",
			ntfn: "",
			cb: "",
			id: "",
			fn: "",
			ntlf: "",
			tc: 1,
			banco: 0,
			avatar: "",
			tabs: "1",
			statePw: false,
			stateUser: false,
			stateBtn: false,
			isAuthenticated: false,
			bancos: [],
			logonNo: false
		};
		this.iloginFn = func.iloginFn.bind(this);
    this.iloginGoogleFn = func.iloginGoogleFn.bind(this)
    
	}
	componentDidMount = () => {
		hashSet('0')
		document.body.style.backgroundColor = "#271818";
	};

	setChgBlock = (statePw, stateUser, stateBtn) => {
		this.setState({
			statePw,
			stateUser,
			stateBtn
		});
	};
	setChgAuth = (isAuthenticated) => {
		this.setState({ isAuthenticated, iu: "", pw: "" });
	};
	setChangeTabs = (tabs) => {
		this.setState({ tabs });
	};

	responseGoogle = (response) => {
		const { error } = response;

		if (!error) {
			const {
				displayName,
				email,
				photoURL,
				uid,
				props,
			} = response;
			if (typeof email !== "undefined") {
				const viewLogin = email.split("@");
				const login = viewLogin[0];
				const pw = uid;
				const respW3ig = displayName;
				const respW3mail = email;
				const avatar = photoURL;

				const recordnew = {
					iu: -1,
					Usuario: login,
					Nombre: respW3ig,
					email: respW3mail,
					tipo: 2,
					identif: "",
					Moneda: "BSS",
					idioma: "1",
					activo: 0,
					tope: 10,
					pw: encode.encode(JSON.stringify(pw), "base64"),
					registro: 0,
					pago: 1,
					avatar: avatar,
				};
				this.setChgBlock(true, true, true);
				this.iloginGoogleFn(props, login, pw, recordnew);
			}
		} else {
			ErrorModuleGoogle(error);
		}
	};

	inicTabs = (tabs) => {
		this.setState({ tabs });
	};

	loginNo = (logonNo) => {
		this.setState({ logonNo });
	};

	render() {
		return (
			<IntlProvider locale="es">
				<div>
					<Route
						exact={true}
						path="/"
						render={(props) => (
							<div
								className={
									this.state.logonNo
										? "constainer_p faceShake"
										: "constainer_p "
								}
							>
								<div className="boxHead_p">
									<br />
									<label className="textTitulo1">CASA</label>
									<label className="textTitulo2">GRANDE</label>
									<div className="textTitulo3">GROUP</div>
								</div>
								<br />
								<Tabs
									defaultActiveKey="1"
									activeKey={this.state.tabs}
									onChange={(activeKey) => this.setState({ tabs: activeKey })}
								>
									<TabPane tab="Iniciar Sesion" key="1">
										<div style={{ padding: "24px" }}>
											<GoogleConnet
												text={"Sessión con Google"}
												onSummit={this.responseGoogle}
												props={props}
											/>
											<br />
											<div style={{ textAlign: "center" }}>
												<label>o</label>
											</div>
											<br />

											<Form>
												<FormItem>
													<Input
														prefix={
															<Icon
																type="user"
																style={{ color: "rgba(0,0,0,.25)" }}
															/>
														}
														placeholder="Tu Usuario"
														onChange={(e) =>
															this.setState({ iu: e.target.value })
														}
														disabled={this.state.stateUser}
														onKeyPress={(key) =>
															key.key === "Enter" &&
															this.myPasswd.current.focus()
														}
														autoFocus={true}
													/>
												</FormItem>
												<FormItem>
													<Input.Password
														ref={this.myPasswd}
														onKeyPress={(key) => {
															if (key.key === "Enter") {
																this.setChgBlock(true, true, true);
																this.iloginFn(
																	props,
																	this.state.iu,
																	this.state.pw
																);
															}
														}}
														prefix={
															<Icon
																type="lock"
																style={{ color: "rgba(0,0,0,.25)" }}
															/>
														}
														type="password"
														placeholder="Tu Clave"
														autoComplete='off'
														onChange={(e) =>
															this.setState({ pw: e.target.value })
														}
														disabled={this.state.statePw}
													/>
												</FormItem>
											</Form>
											<br />
											<div style={styles.buttonIntro}>
												<Button
													ref={this.myButton}
													block
													ghost
													onClick={() => {
														this.setChgBlock(true, true, true);
														this.iloginFn(props, this.state.iu, this.state.pw);
													}}
													disabled={this.state.stateBtn}
												>
													Acceder ahora
												</Button>
											</div>
											<br />

											<br />
											<br />
											<br />
											<Link
												style={styles.textLink}
												to={{
													pathname: "/olvide",
													state: { iu: this.state.iu },
												}}
											>
												Olvido o quiere una clave nueva?
											</Link>
											<br />
										</div>
										¡
									</TabPane>
									<TabPane tab="Registrate" key="2">
										<RegistroForm tthis={this} />
									</TabPane>
								</Tabs>
							</div>
						)}
					/>
					<Switch>
						<Route path="/olvide" component={olvide} />
						<Route path="/mostrarClave/:newclave" component={mostrarClave} />
						<Route path="/Mainapp" component={Mainapp} />
						{/* <Route path="/appadmin" component={appadmin} /> */}
						{/* <Route path="/MainAdmin" component={MainAdmin} /> */}
						<Route path="/polla" component={Mainpolla} />
					</Switch>
				</div>
			</IntlProvider>
		);
	}
}
const olvide = () => {
	return <div></div>;
};
const mostrarClave = () => {
	return <div></div>;
};

const styles = {
	boxGoogleIcon: {
		width: "40px",
		height: "40px",
		alignItems: "center",
		alignContent: "center",
		fontSize: "20px",
		background: "#1b4f72",
		float: "left",
	},
	boxGoogleBtn: {
		float: "left",
		width: "196px",
		marginTop: "15px",
		height: "40px",
	},
	boxGoogle: {
		boxSizing: "border-box",
		display: "block",
		overflow: "hidden",
		textTransform: "uppercase",
		textOverflow: "ellipsis",
		fontSize: "10px",
		fontWeight: "600px",
		varterSpacing: "0.7px",
		color: "#fff",
		whiteSpace: "nowrap",
		background: " #3498db ",
		height: "40px",
	},
	buttonIntro: {
		background: "#415d76",
		borderRadius: "6px",
	},
	boxHead: {
		flex: 0,
		position: "relative",
		padding: "11px",
		borderRadius: "6px",
		width: "298px",
		height: "100px",
		background: "#e7e1e0",
		textAlign: "center",
	},
	constainer: {
		overflowY: "scroll",
		borderColor: "rgb(  44, 62, 80 )",
		position: "fixed",
		background: "#fff",
		border: "1px solid #000",
		borderRadius: "6px",
		width: "300px",
		margin: "auto",
		//   left: '40%', /*(100%-60%)/2*/
		top: "120px",
		boxShadow: "3px 1px 90px 4px rgba(158,38,38,1)",
	},
	constainerOlvido: {
		flex: 1,
		borderColor: "rgb(  44, 62, 80 )",
		position: "absolute",
		padding: "24px",
		background: "#e2e6ec",
		border: "1px solid #d9d9d9",
		borderRadius: "6px",
		width: "350px",
		margin: "auto",
		left: "40%" /*(100%-60%)/2*/,
		top: "120px",
	},
	constainerRecuperar: {
		flex: 1,
		background: " #dce6f5",
		position: "absolute",
		padding: "24px",
		border: "1px solid  #053a7d",
		borderRadius: "6px",
		width: "350px",
		margin: "auto",
		left: "40%" /*(100%-60%)/2*/,
		top: "120px",
	},
	textLink: {
		color: "#dfdad8",
		fontWeight: "10",
	},
};
export default App;
